import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    flagShowOverlay: true,
    flagShowModalPin: false,
    flagOverlayModalPin: false,
    flagMenuOpen: false
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    flagShowOverlay: state => state.flagShowOverlay,
    flagShowModalPin: state => state.flagShowModalPin,
    flagOverlayModalPin: state => state.flagOverlayModalPin,
    flagMenuOpen: state => state.flagMenuOpen
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    UPDATE_FLAG_SHOW_OVERLAY(state, val) {
      state.flagShowOverlay = val;
    },
    UPDATE_FLAG_SHOW_MODAL_PIN(state, val) {
        state.flagShowModalPin = val
    },
    UPDATE_FLAG_OVERLAY_MODAL_PIN(state, val) {
        state.flagOverlayModalPin = val
    },
    UPDATE_FLAG_MENU_OPEN(state, val) {
        state.flagMenuOpen = val
    }
  },
  actions: {
    removeRequestLoading({commit}) {
        const requestList = JSON.parse(localStorage.getItem('requestList'));
        requestList.length === 0 && commit('UPDATE_FLAG_SHOW_OVERLAY', false);
    }
  },
}
