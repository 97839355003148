import Vue from "vue";

const prodURL =
    window.location.origin.includes("indsiders.com") &&
    !window.location.origin.includes("dev") &&
    !window.location.origin.includes("homolog");
const homologURL = window.location.origin.includes("homolog");
const localhostURL = window.location.origin.includes("localhost");

if (prodURL) {
    Vue.prototype.$mySfcLink = "https://mysfc.indsiders.com/";
    Vue.prototype.$myIndLink = "https://myind.indsiders.com/";
} else if (homologURL) {
    Vue.prototype.$mySfcLink = "https://mysfc-homolog.indsiders.com/";
    Vue.prototype.$myIndLink = "https://myind-homolog.indsiders.com/";
} else if (localhostURL) {
    Vue.prototype.$mySfcLink = "http://localhost:8081/";
    Vue.prototype.$myIndLink = "http://localhost:8080/";
} else {
    Vue.prototype.$mySfcLink = "https://mysfc-dev.indsiders.com/";
    Vue.prototype.$myIndLink = "https://myind-dev.indsiders.com/";
}
